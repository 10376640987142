<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("jobRiskAssessments") }}</h1>
      <br>
      <div class="flex flex-wrap items-center justify-between">
      <div class="flex items-center">
<!--       <vs-button  @click="moov()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
 -->   </div>
      <div class="flex items-center">
        <vs-button v-if="action_button === 1" @click="missionStep()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
        <vs-button v-if="action_button === 2" @click="validationStep()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
      </div>
      </div>
      <div  v-if="activePrompt1"  class="clearfix">

      <div  class="vx-row">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
        <p>{{ $t("mission") }}<b style="color: #ff6141"></b></p>
        <vs-input
        disabled
        name="mission_name"
        v-model="mission_name"
        class="w-full"/>   
        </div>
         
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <div class="vx-col sm:w-11/12 md:w-11/12 lg:w-11/12 xs:w-11/12 inline-flex mt-6">
            <p>{{ $t("library") }}<b style="color: #ff6141" ></b> </p>
              <vs-select
                collapse-chips
                :closeOnSelect="false"
                v-model="library_file_path"
                data-vv-validate-on="blur"
                autocomplete
                name="library_file_path"
                class="w-full ml-2 "
            >
            <vs-select-item :key="item2.name" :value="item2.file_path" :text="item2.name" v-for="item2 in libraries" /></vs-select>
            <vs-button class="ml-4" color="primary" type="filled"  @click.stop="showLibrary(library_file_path)">{{ $t("Voir") }}</vs-button>
          </div>
          </div>

      <vx-card  class="mt-10" no-shadow card-border>
      <div class="vx-row ">
      <div class="vx-col w-full">
      <div class="flex items-end px-3">
        <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
        <span class="font-medium text-lg leading-none">EVALUATION</span>
      </div>
      <vs-divider />
      </div>
      </div>
      <div class="vx-row ">
            <vs-table stripe class="w-full  " :data="risks_activity">
            <template slot="thead">
<!--               <vs-th>N°</vs-th>
 -->              <vs-th>{{ $t("Libelle") }}</vs-th>
<!--               <vs-th>{{ $t("riskDetails") }}</vs-th>
 -->              <vs-th v-if="user_role !== 'managerSubcontractor'">{{ $t("initialRisk") }}</vs-th>
             <!--  <vs-th>{{ $t("initialRiskProbability") }}</vs-th>
              <vs-th>{{ $t("initialRiskSeverity") }}</vs-th> -->
              <vs-th>{{ $t("comapasatoryMeasures") }}</vs-th>
            <!--   <vs-th>{{ $t("residualRiskProbability") }}</vs-th>
              <vs-th>{{ $t("residualRiskSeverity") }}</vs-th> -->
              <vs-th>{{ $t("residualRisk") }}</vs-th>
              <vs-th>{{ $t("documents") }}</vs-th>
           
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
              
              
<!--                 <vs-td>{{indextr+1}}</vs-td>
 -->            
                <vs-td >{{tr.activity.name}}</vs-td>
                
               <!--  <vs-td >
                  <p>{{tr.riskDetails}}</p>
                </vs-td>
 -->
<!--  v-if="user_role !== 'managerSubcontractor'" 
 -->                <vs-td >
<!--                   <p>{{tr.initialRisk}}</p>
 -->                  <vs-chip  class="number mr-4" :color="getOrderStatusColor1(tr.initialRisk)" ><b style="color: black">{{ tr.initialRisk }}</b></vs-chip>

                </vs-td>

               <!--  <vs-td >
                  <p>{{tr.initialRiskProbability}}</p>
                </vs-td> -->

              <!--   <vs-td >
                  <p>{{tr.initialRiskSeverity}}</p>
                </vs-td> -->

              <vs-td >
                <vs-textarea
                @change="comapasatoryMeasures_tr(tr,indextr)"
                  v-model="tr.comapasatoryMeasures"
                  rows="2"
                  class="w-full" ></vs-textarea>
              </vs-td>

            <!--   <vs-td >
                <vs-select
                @input="residualRiskProbability_tr(tr,indextr)"
                v-model="tr.residualRiskProbability"
                  autocomplete
                  class="w-full"
                  >
                <vs-select-item :key="item1" :value="item1.key" :text="item1.name" v-for="item1 in evaluations" /></vs-select>
              </vs-td> -->

             <!--  <vs-td >
                <vs-select
                @input= "residualRiskSeverity_tr(tr,indextr)"
                v-model="tr.residualRiskSeverity "
                  autocomplete
                  class="w-full"
                  >
                <vs-select-item :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in evaluations" /></vs-select>
            
              </vs-td>
 -->
              <vs-td >
                <vs-chip  class="number mr-4" :color="getOrderStatusColor1(tr.residualRisk)" ><b style="color: black">{{ tr.residualRisk }}</b></vs-chip>
                <vs-button @click="selectRisk(tr)" size="small" color="primary" type="filled" icon-pack="feather" icon="icon-plusCircle">{{ $t("Choisir") }}</vs-button>

              </vs-td>
              <vs-td >
<!--                 <p v-for="(item, index) in name_file1_3" :key="index" ><b style="color:orange">{{item }}</b></p>
 -->                <input
                v-validate="'required'"
                class="btn-add-new  p-2 mb-6 
                  rounded-lg cursor-pointer flex items-center
                  justify-center font-medium text-base text-warning  "

                  name="indextr" type="file"   ref="indextr" @change="updateCurrImg1_3" accept=".pdf,image/*" >
                  <span class="text-danger text-sm" v-show="errors.has('indextr')">{{ $t("fichier_obligatoire") }}</span>

              </vs-td>
              </vs-tr>
            </template>
            </vs-table>
      </div>
     <!--  <div id="app">
        <div class="grid">
          <div v-for="number in numbers" :key="number" :class="{ selected: number.selected , level: number.riskLevel }" @click="selectNumber(number)">
            {{ number.value }}
          </div>
        </div>
      </div> -->
    
      
      </vx-card>

      </div>
      <vs-button color="success"  class="float-right mt-4" @click="submitData()" >Valider</vs-button>
      </div>
      
      <div  v-if="activePrompt2"  class="clearfix">
        <div  class="vx-row">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
        <p>{{ $t("mission") }}<b style="color: #ff6141"></b></p>
        <vs-input
        disabled
        name="mission_name"
        v-model="mission_name"
        class="w-full"/>
      </div>
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <div class="vx-col sm:w-11/12 md:w-11/12 lg:w-11/12 xs:w-11/12 inline-flex mt-6">
            <p>{{ $t("library") }}<b style="color: #ff6141" ></b> </p>
              <vs-select
                collapse-chips
                :closeOnSelect="false"
                v-model="library_file_path"
                data-vv-validate-on="blur"
                autocomplete
                name="library_file_path"
                class="w-full ml-2 "
            >
            <vs-select-item :key="item2.name" :value="item2.file_path" :text="item2.name" v-for="item2 in libraries" /></vs-select>
            <vs-button class="ml-4" color="primary" type="filled"  @click.stop="showLibrary(library_file_path)">{{ $t("Voir") }}</vs-button>
          </div>
          </div>

      <vx-card  class="mt-10" no-shadow card-border>
      <div class="vx-row ">
      <div class="vx-col w-full">
      <div class="flex items-end px-3">
        <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
        <span class="font-medium text-lg leading-none">EVALUATION</span>
      </div>
      <vs-divider />
      </div>
      </div>
      <div class="vx-row ">
            <vs-table stripe class="w-full  " :data="produits_computed_show">
            <template slot="thead">
<!--               <vs-th>N°</vs-th>
 -->              <vs-th>{{ $t("Libelle") }}</vs-th>
<!--               <vs-th>{{ $t("riskDetails") }}</vs-th>
 -->              <vs-th>{{ $t("initialRisk") }}</vs-th>
             <!--  <vs-th>{{ $t("initialRiskProbability") }}</vs-th>
              <vs-th>{{ $t("initialRiskSeverity") }}</vs-th> -->
              <vs-th>{{ $t("comapasatoryMeasures") }}</vs-th>
            <!--   <vs-th>{{ $t("residualRiskProbability") }}</vs-th>
              <vs-th>{{ $t("residualRiskSeverity") }}</vs-th> -->
              <vs-th>{{ $t("residualRisk") }}</vs-th>
              <vs-th>{{ $t("documents") }}</vs-th>
            
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
              
              
<!--                 <vs-td>{{indextr+1}}</vs-td>
 -->            
                <vs-td >{{tr.activity.name}}</vs-td>
                
               <!--  <vs-td >
                  <p>{{tr.riskDetails}}</p>
                </vs-td>
 -->
<!--  v-if="user_role !== 'managerSubcontractor'"
 -->                <vs-td>
                  <!-- <p>{{tr.initialRisk}}</p> -->
                  <vs-chip  class="number mr-4" :color="getOrderStatusColor1(tr.initialRisk)" ><b style="color: black">{{ tr.initialRisk }}</b></vs-chip>

                </vs-td>

               <!--  <vs-td >
                  <p>{{tr.initialRiskProbability}}</p>
                </vs-td> -->

              <!--   <vs-td >
                  <p>{{tr.initialRiskSeverity}}</p>
                </vs-td> -->

              <vs-td >
                <vs-textarea
                @change="comapasatoryMeasures_tr(tr,indextr)"
                  v-model="tr.comapasatoryMeasures"
                  rows="2"
                  class="w-full" ></vs-textarea>
              </vs-td>

            <!--   <vs-td >
                <vs-select
                @input="residualRiskProbability_tr(tr,indextr)"
                v-model="tr.residualRiskProbability"
                  autocomplete
                  class="w-full"
                  >
                <vs-select-item :key="item1" :value="item1.key" :text="item1.name" v-for="item1 in evaluations" /></vs-select>
              </vs-td> -->

             <!--  <vs-td >
                <vs-select
                @input= "residualRiskSeverity_tr(tr,indextr)"
                v-model="tr.residualRiskSeverity "
                  autocomplete
                  class="w-full"
                  >
                <vs-select-item :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in evaluations" /></vs-select>
            
              </vs-td>
 -->
              <vs-td  >
                <vs-chip  class="number mr-4" :color="getOrderStatusColor1(tr.residualRisk)" ><b style="color: black">{{ tr.residualRisk }}</b></vs-chip>
                <vs-button @click="selectRisk(tr)" size="small" color="primary" type="filled" icon-pack="feather" icon="icon-plusCircle">Choisir</vs-button>

              </vs-td>
              <vs-td >
<!--                 <p v-for="(item, index) in name_file1_3" :key="index" ><b style="color:orange">{{item }}</b></p>
 -->                <input
                class="btn-add-new  p-2 mb-6 
                  rounded-lg cursor-pointer flex items-center
                  justify-center text-lg font-medium text-warning  "

                  name="indextr" type="file"   ref="indextr" @change="updateCurrImg1_3" accept=".pdf,image/*">
              </vs-td>
              </vs-tr>
            </template>
            </vs-table>
      </div>
     <!--  <div id="app">
        <div class="grid">
          <div v-for="number in numbers" :key="number" :class="{ selected: number.selected , level: number.riskLevel }" @click="selectNumber(number)">
            {{ number.value }}
          </div>
        </div>
      </div> -->
    
      
      </vx-card>

      </div>
      <vs-button color="success"  class="float-right mt-4" @click="submitData()" >Valider</vs-button>
      </div>

      <div  v-if="activePrompt3"  class="clearfix">

        <div  class="vx-row">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
        <p>{{ $t("mission") }}<b style="color: #ff6141"></b></p>
        <vs-input
        disabled
        name="mission_name"
        v-model="mission_name"
        class="w-full"/>
        </div>
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
            <div class="vx-col sm:w-11/12 md:w-11/12 lg:w-11/12 xs:w-11/12 inline-flex mt-6">
            <p>{{ $t("library") }}<b style="color: #ff6141" ></b> </p>
              <vs-select
                collapse-chips
                :closeOnSelect="false"
                v-model="library_file_path"
                data-vv-validate-on="blur"
                autocomplete
                name="library_file_path"
                class="w-full ml-2 "
            >
            <vs-select-item :key="item2.name" :value="item2.file_path" :text="item2.name" v-for="item2 in libraries" /></vs-select>
            <vs-button class="ml-4" color="primary" type="filled"  @click.stop="showLibrary(library_file_path)">{{ $t("Voir") }}</vs-button>
          </div>
          </div>

      <vx-card  class="mt-10" no-shadow card-border>
      <div class="vx-row ">
      <div class="vx-col w-full">
      <div class="flex items-end px-3">
        <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
        <span class="font-medium text-lg leading-none">EVALUATION</span>
      </div>
      <vs-divider />
      </div>
      </div>
      <div class="vx-row ">
            <vs-table stripe class="w-full  " :data="produits_computed_show">
            <template slot="thead">
<!--               <vs-th>N°</vs-th>
 -->              <vs-th>{{ $t("Libelle") }}</vs-th>
<!--               <vs-th>{{ $t("riskDetails") }}</vs-th>
  v-if="user_role !== 'managerSubcontractor'"
 -->              <vs-th  >{{ $t("initialRisk") }}</vs-th>
             <!--  <vs-th>{{ $t("initialRiskProbability") }}</vs-th>
              <vs-th>{{ $t("initialRiskSeverity") }}</vs-th> -->
              <vs-th>{{ $t("comapasatoryMeasures") }}</vs-th>
            <!--   <vs-th>{{ $t("residualRiskProbability") }}</vs-th>
              <vs-th>{{ $t("residualRiskSeverity") }}</vs-th> -->
              <vs-th>{{ $t("residualRisk") }}</vs-th>
              <vs-th>{{ $t("documents") }}</vs-th>
            
              <vs-th>{{ $t("nokiaComment") }}</vs-th>

              <vs-th>{{ $t("nokiaValidation") }}</vs-th> 
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
              
              
<!--                 <vs-td>{{indextr+1}}</vs-td>
 -->            
                <vs-td >{{tr.activity.name}}</vs-td>
                
               <!--  <vs-td >
                  <p>{{tr.riskDetails}}</p>
                </vs-td>
 -->
<!--  v-if="user_role !== 'managerSubcontractor'"
 -->                <vs-td>
<!--                   <p>{{tr.initialRisk}}</p>
 -->                  <vs-chip  class="number mr-4" :color="getOrderStatusColor1(tr.initialRisk)" ><b style="color: black">{{ tr.initialRisk }}</b></vs-chip>

                </vs-td>

               <!--  <vs-td >
                  <p>{{tr.initialRiskProbability}}</p>
                </vs-td> -->

              <!--   <vs-td >
                  <p>{{tr.initialRiskSeverity}}</p>
                </vs-td> -->

              <vs-td >
                <vs-textarea
                  disabled
                  v-model="tr.comapasatoryMeasures"
                  rows="2"
                  class="w-full" ></vs-textarea>
              </vs-td>

            <!--   <vs-td >
                <vs-select
                @input="residualRiskProbability_tr(tr,indextr)"
                v-model="tr.residualRiskProbability"
                  autocomplete
                  class="w-full"
                  >
                <vs-select-item :key="item1" :value="item1.key" :text="item1.name" v-for="item1 in evaluations" /></vs-select>
              </vs-td> -->

             <!--  <vs-td >
                <vs-select
                @input= "residualRiskSeverity_tr(tr,indextr)"
                v-model="tr.residualRiskSeverity "
                  autocomplete
                  class="w-full"
                  >
                <vs-select-item :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in evaluations" /></vs-select>
            
              </vs-td>
 -->
              <vs-td  >
                <vs-chip  class="number mr-4" :color="getOrderStatusColor1(tr.residualRisk)" ><b style="color: black">{{ tr.residualRisk }}</b></vs-chip>
<!--                 <vs-button @click="selectRisk(tr)" size="small" color="primary" type="filled" icon-pack="feather" icon="icon-plusCircle">Choisir</vs-button>
 -->
              </vs-td>
               <vs-td  >
                <vs-button @click="openImage(tr.files)" size="small" color="primary" type="filled" icon-pack="feather" icon="icon-plusCircle">{{ $t("Voir") }}</vs-button>
              </vs-td>
              <vs-td >
                <vs-textarea
                  disabled
                  v-model="tr.nokiaComment"
                  rows="2"
                  class="w-full" ></vs-textarea>
              </vs-td>
              <vs-td >
              <vs-checkbox disabled v-model="tr.nokiaValidation " ></vs-checkbox>
              </vs-td>
              </vs-tr>
            </template>
            </vs-table>
      </div>
     <!--  <div id="app">
        <div class="grid">
          <div v-for="number in numbers" :key="number" :class="{ selected: number.selected , level: number.riskLevel }" @click="selectNumber(number)">
            {{ number.value }}
          </div>
        </div>
      </div> -->
    
      
      </vx-card>

      </div>
        <vs-button color="primary"  class="float-right mt-4" @click="quit()" >{{ $t("Quit") }}</vs-button>
      </div>

      <vs-popup  v-if="activePrompt4"  :title="this.$t('risk_residuel')" :active.sync="activePrompt4">
        <div id="app">
         <!--  <div class="vx-col sm:w-1/4 md:w-1/4 lg:w-1/4 xs:w-1/4" >
              <div class="matrix" v-for="row in matrix2" :key="row.id">
                  <div class="mt-3"><b>{{ row.risk_name_inv }}</b></div>
                <div>
                    
                  </div> 
              </div>
          </div> -->
          <div   class="matrix ">
            <div  v-for="row in matrix2" :key="row.id">

              <div  v-for="number in row.numbers" :key="number"   @click="selectNumber(number)">
              <div> 
              <vs-chip  :color="getOrderStatusColor1( number.value )" ></vs-chip>
                   
<!--             <p :style="'color: ' + getOrderStatusColor1( number.value )"></p>
 -->            <b class="mr-10" >{{  number.value  }}</b>
              </div>
    
            </div>
           
            </div>

            <!-- <div   v-for="row in matrix2" :key="row.id">
              <div><b>{{ row.risk_name }}</b></div>
            <div>
                
              </div> 
            </div> -->

          </div>
          <br>
          <div class="vx-row">
            <div class="legende vx-col sm:w-3/4 md:w-3/4 lg:w-3/4 xs:w-3/4">
                <h4>EVALUATION</h4>
                <br>
              <ul>
                <p >
                <span><b>{{ $t("risk_r1") }}</b> {{ $t("risk_r2") }} <b>{{ $t("risk_r3") }}</b>{{ $t("risk_r4") }}<b>{{ $t("risk_r5") }}</b></span>
                </p>
                <br>
                <li><b>{{ $t("axe_vertical") }}</b></li>
                <li><b>{{ $t("axe_horizontal") }}</b></li>
                <li><b>{{ $t("graduation") }}</b></li>
              </ul>
          </div>
          <div class="legende vx-col sm:w-1/4 md:w-1/4 lg:w-1/4 xs:w-1/4 ">
                <h4>{{ $t("legende") }}</h4>
                <br>
                <ul>
                  <li><b>{{ $t("negligeable_1") }}</b></li>
                  <li><b>{{ $t("modere_2") }}</b></li>
                  <li><b>{{ $t("eleve_3") }}</b></li>
                  <li><b>{{ $t("considerable_4") }}</b></li>
                  <li><b>{{ $t("extreme_5") }}</b></li>
                </ul>
          </div>
          </div>
        </div>      
      </vs-popup>

      <div  v-if="activePrompt5"  class="clearfix">
      <div  class="vx-row">
      <div class="vx-col sm:w-1/2 md:w-1/2 lg:1/2 xs:w-1/2">
      <p>{{ $t("mission") }}<b style="color: #ff6141"></b></p>
      <vs-input
      disabled
      name="mission_name"
      v-model="mission_name"
      class="w-full"/>
      </div>


      <vx-card  class="mt-10" no-shadow card-border>
      <div class="vx-row ">
      <div class="vx-col w-full">
      <div class="flex items-end px-3">
      <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
      <span class="font-medium text-lg leading-none">EVALUATION</span>
      </div>
      <vs-divider />
      </div>
      </div>
      <div class="vx-row ">
          <vs-table stripe class="w-full  " :data="produits_computed_show">
          <template slot="thead">
      <!--               <vs-th>N°</vs-th>
      -->              <vs-th>{{ $t("Libelle") }}</vs-th>
      <!--               <vs-th>{{ $t("riskDetails") }}</vs-th>
      -->              <vs-th>{{ $t("initialRisk") }}</vs-th>
          <!--  <vs-th>{{ $t("initialRiskProbability") }}</vs-th>
            <vs-th>{{ $t("initialRiskSeverity") }}</vs-th> -->
            <vs-th>{{ $t("comapasatoryMeasures") }}</vs-th>
          <!--   <vs-th>{{ $t("residualRiskProbability") }}</vs-th>
            <vs-th>{{ $t("residualRiskSeverity") }}</vs-th> -->
            <vs-th>{{ $t("residualRisk") }}</vs-th>
              <vs-th>{{ $t("documents") }}</vs-th>
       
            <vs-th>{{ $t("nokiaComment") }}</vs-th>

            <vs-th>{{ $t("nokiaValidation") }}</vs-th>     
          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
            
            
      <!--                 <vs-td>{{indextr+1}}</vs-td>
      -->            
              <vs-td >{{tr.activity.name}}</vs-td>
              
            <!--  <vs-td >
                <p>{{tr.riskDetails}}</p>
              </vs-td>
      -->
<!--       v-if="user_role !== 'managerSubcontractor'"
 -->              <vs-td>
<!--                 <p>{{tr.initialRisk}}</p>
 -->                <vs-chip  class="number mr-4" :color="getOrderStatusColor1(tr.initialRisk)" ><b style="color: black">{{ tr.initialRisk }}</b></vs-chip>

              </vs-td>

            <!--  <vs-td >
                <p>{{tr.initialRiskProbability}}</p>
              </vs-td> -->

            <!--   <vs-td >
                <p>{{tr.initialRiskSeverity}}</p>
              </vs-td> -->

            <vs-td >
              <vs-textarea
                disabled
                v-model="tr.comapasatoryMeasures"
                rows="2"
                class="w-full" ></vs-textarea>
            </vs-td>

          <!--   <vs-td >
              <vs-select
              @input="residualRiskProbability_tr(tr,indextr)"
              v-model="tr.residualRiskProbability"
                autocomplete
                class="w-full"
                >
              <vs-select-item :key="item1" :value="item1.key" :text="item1.name" v-for="item1 in evaluations" /></vs-select>
            </vs-td> -->

          <!--  <vs-td >
              <vs-select
              @input= "residualRiskSeverity_tr(tr,indextr)"
              v-model="tr.residualRiskSeverity "
                autocomplete
                class="w-full"
                >
              <vs-select-item :key="item2" :value="item2.key" :text="item2.name" v-for="item2 in evaluations" /></vs-select>
          
            </vs-td>
      -->
            <vs-td  >
              <vs-chip  class="number mr-4" :color="getOrderStatusColor1(tr.residualRisk)" ><b style="color: black">{{ tr.residualRisk }}</b></vs-chip>
      <!--    <vs-button @click="selectRisk(tr)" size="small" color="primary" type="filled" icon-pack="feather" icon="icon-plusCircle">Choisir</vs-button>
      -->
            </vs-td>
             <vs-td  >
              <vs-button @click="openImage(tr.files)" size="small" color="primary" type="filled" icon-pack="feather" icon="icon-plusCircle">{{ $t("Voir") }}</vs-button>
            </vs-td>
            <vs-td v-if="user_role == 'managerSubcontractor'" >
                <vs-textarea
                disabled
                @change="nokiaComment_tr(tr)"
                  v-model="tr.nokiaComment"
                  rows="2"
                  class="w-full" ></vs-textarea>
            </vs-td>

              <vs-td v-if="user_role == 'managerSubcontractor'" >
              <vs-checkbox disabled @change="nokiaValidation_tr(tr)" v-model="tr.nokiaValidation " ></vs-checkbox>
              </vs-td>

              <vs-td v-if="user_role !== 'managerSubcontractor'">
                <vs-textarea
                  @change="nokiaComment_tr(tr)"
                  v-model="tr.nokiaComment"
                  rows="2"
                  class="w-full" ></vs-textarea>
             </vs-td>

              <vs-td v-if="user_role !== 'managerSubcontractor'">
              <vs-checkbox  @change="nokiaValidation_tr(tr)" v-model="tr.nokiaValidation " ></vs-checkbox>
              </vs-td>
            </vs-tr>
          </template>
          </vs-table>
      </div>
      <!--  <div id="app">
      <div class="grid">
        <div v-for="number in numbers" :key="number" :class="{ selected: number.selected , level: number.riskLevel }" @click="selectNumber(number)">
          {{ number.value }}
        </div>
      </div>
      </div> -->


      </vx-card>

      </div>
      <vs-button v-if="user_role !== 'managerSubcontractor'" color="success"  class="float-right mt-4" @click="confimationRisk()" >{{ $t("Confirmer") }}</vs-button>
      </div>
      
    </div>
  </template>
<script>


export default {
  data () {
    return {
      action_button:'',
      missionControle_data:'',
      library_file_path:'',
      libraries:[],
      user_role : '',
      matrix: [
        {
          id: 1,
          risk_name :'NÉGLIGEABLE',
          risk_name_inv :'EXTRÊME',
          numbers: [
            { value: 5, selected: false, riskLevel: 'negligible' },
            { value: 4, selected: false, riskLevel: 'negligible' },
            { value: 3, selected: false, riskLevel: 'negligible' },
            { value: 2, selected: false, riskLevel: 'negligible' },
            { value: 1, selected: false, riskLevel: 'negligible' }
          ]
        },
        {
          id: 2,
          risk_name :'MODÉRÉ',
          risk_name_inv :'CONSIDÉRABLE',

          numbers: [
            { value: 10, selected: false, riskLevel: 'negligible' },
            { value: 8, selected: false, riskLevel: 'negligible' },
            { value: 6, selected: false, riskLevel: 'negligible' },
            { value: 4, selected: false, riskLevel: 'negligible' },
            { value: 2, selected: false, riskLevel: 'negligible' }
          ]
        },
        {
          id: 3,
          risk_name :'ÉLEVÉ',
          risk_name_inv :'ÉLEVÉ',
          numbers: [
            { value: 15, selected: false, riskLevel: 'negligible' },
            { value: 12, selected: false, riskLevel: 'negligible' },
            { value: 9, selected: false, riskLevel: 'negligible' },
            { value: 6, selected: false, riskLevel: 'negligible' },
            { value: 3, selected: false, riskLevel: 'negligible' }
          ]
        },
        {
          id: 4,
          risk_name :'CONSIDÉRABLE',
          risk_name_inv :'MODÉRÉ',
          numbers: [
            { value: 20, selected: false, riskLevel: 'negligible' },
            { value: 16, selected: false, riskLevel: 'negligible' },
            { value: 12, selected: false, riskLevel: 'negligible' },
            { value: 8, selected: false, riskLevel: 'negligible' },
            { value: 4, selected: false, riskLevel: 'negligible' }
          ]
        },
        {
          id: 5,
          risk_name :'EXTRÊME',
          risk_name_inv :'NÉGLIGEABLE',
          numbers: [
            { value: 25, selected: false, riskLevel: 'negligible' },
            { value: 20, selected: false, riskLevel: 'negligible' },
            { value: 15, selected: false, riskLevel: 'negligible' },
            { value: 10, selected: false, riskLevel: 'negligible' },
            { value: 5, selected: false, riskLevel: 'negligible' }
          ]
        }
      ],
      matrix2:[],
      evaluations: [
        {
          key: 0,
          name: 'RIEN'
        },
        {
          key: 1,
          name: 'NÉGLIGEABLE'
        },
        {
          key: 2,
          name: 'MODÉRÉ'
        },
        {
          key: 3,
          name: 'ÉLEVÉ'
        },
        {
          key: 4,
          name: 'CONSIDÉRABLE'
        },
        
        {
          key: 5,
          name: 'EXTRÊME'
        }
        
      ],
      evaluations_en: [
        {
          key: 0,
          name: 'NOTHING'
        },
        {
          key: 1,
          name: 'NEGLIGIBLE'
        },
        {
          key: 2,
          name: 'MODERATE'
        },
        {
          key: 3,
          name: 'HIGHT'
        },
        {
          key: 4,
          name: 'CONSIDERABLE'
        },
        
        {
          key: 5,
          name: 'EXTREME'
        }
        
      ],
      repProduit:[],
      produits_computed_show:[],
      jobrisk:{},
      risks:[],
      comapasatoryMeasures : [],
      residualRisks : [],
      residualRiskSeverities  : [],
      residualRiskProbabilities : [],
      documents : [],
      mission_name:'',
      mission_id: '',
      mission_data:{},
      activePrompt5:false,
      activePrompt4:false,
      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      Check_update:false,
      id:'',
      dataImg1_3:[],
      name_file1_3:[],
      dataImg1_4:[],
      name_file1_4:[],
      risks_activity:[],
      isMounted: false,
      itemsPerPage: 20,
      update_tr : {},
      jobRiskAssessment_object:[]
    }
  },
 
  computed: {
    getlang () {
      return window.getPrendTaCom.$i18n.locale
    },
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.jobRiskAssessments.length
    }
  },
 
  methods: {
    showLibrary (file) {
      if (file) {
        window.open(file, '_blank')
      }
    },
    openImage (file) {
      if (file) {
        const file_flash = file.substring(9)
        window.open(`https://dev.jra.mitigarisk.com/digiraDev/${file_flash}`, '_blank')
      }
    },
    selectRisk (tr) {
      this.update_tr = {}
      this.matrix2 = this.matrix
      this.update_tr = tr
      this.activePrompt4 = true
    },
    selectNumber (number) {
      number.selected = !number.selected
      this.matrix2 = []
      this.update_tr.residualRisk = parseInt(number.value)
      this.activePrompt4 = false
      this.UPDATE_ITEM_PRODUIT(this.produits_computed_show,  this.update_tr)
    },
    getOrderStatusColor1 (status) {
      if (status === 0)   return '#3D85C6'
      if (status === 1)   return '#00AF50'
      if (status === 2)   return '#00AF50'
      if (status === 3)   return '#00AF50'
      if (status === 4)   return '#92D14F'
      if (status === 5)   return '#FFFF00'
      if (status === 6)   return '#92D14F'
      if (status === 8)   return '#E6FF00'
      if (status === 9)   return '#FFFF00'
      if (status === 10)  return '#FFC000'
      if (status === 12)  return '#FFC000'
      if (status === 15)  return '#FE0000'
      if (status === 16)  return '#FE0000'
      if (status === 20)  return '#FE0000'
      if (status === 25)  return '#FE0000'
    },
    missionStep () {
      this.$router.push('/Planifications').catch(() => {})
    },
    validationStep () {
      this.$router.push('/Validations').catch(() => {})
    },
    /*  residualRisk_tr (tr) {
      this.UPDATE_ITEM_PRODUIT(this.risks_activity, tr)
    }, */
    residualRiskSeverity_tr (tr) {
      tr.residualRisk = parseInt((tr.residualRiskSeverity) * parseInt(tr.residualRiskProbability))
      this.UPDATE_ITEM_PRODUIT(this.risks_activity, tr)

    },
    residualRiskProbability_tr (tr) {
     
      tr.residualRisk = parseInt((tr.residualRiskSeverity) * parseInt(tr.residualRiskProbability))
      this.UPDATE_ITEM_PRODUIT(this.risks_activity, tr)

    },
    comapasatoryMeasures_tr (tr) {
      this.UPDATE_ITEM_PRODUIT(this.risks_activity, tr)
    },
    nokiaComment_tr (tr) {
      this.UPDATE_ITEM_PRODUIT(this.risks_activity, tr)
    },
    nokiaValidation_tr (tr) {
      this.UPDATE_ITEM_PRODUIT(this.produits_computed_show, tr)
    },
    confimationRisk () {
      const jobRiskAssessmentHasRisk = []
      const nokiaValidation = []
      const nokiaComment = []
      for (let i = 0; i < this.produits_computed_show.length; i++) {
        jobRiskAssessmentHasRisk.push(this.produits_computed_show[i].id_job)
        nokiaValidation.push(this.produits_computed_show[i].nokiaValidation)
        nokiaComment.push(this.produits_computed_show[i].nokiaComment)
      } 
      this.$vs.dialog({
        type:'confirm',
        color: 'success',
        title: 'Confirmation',
        text: this.$t('texte_validation_evaluation'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.post('validate-and-comment-jobRiskAssessment/', { jobRiskAssessmentHasRisk, nokiaValidation, nokiaComment  })
            .then(response => {
              this.missionStep()
              window.getPrendTaCom.success(this.$t('validation_succes'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('validation_echec')})
            })
        }
      }) 
    },
    addNewData () {
      this.$validator.reset()
      this.intialise()
      this.Check_update = false
      this.activePrompt1 = true
    },
   
    ShowData (data) {
     
      this.intialise()
      this.produits_computed_show = []
      this.repProduit = []
      this.id = data.id
      const z = data.jobRiskAssessmentHasRisks.length
      for (let i = 0; i < z; i++) {
        const obj = {
          id: '',
          activity:'',
          initialRisk: '',
          initialRiskSeverity: '',
          initialRiskProbability: '',
          comapasatoryMeasures: '',
          residualRisk: '',
          residualRiskSeverity: '',
          residualRiskProbability: '',
          riskDetails: '',
          nokiaComment:'',
          nokiaValidation:'',
          files:'',
          files_id:''
        }

        obj.id = data.jobRiskAssessmentHasRisks[i].id
        obj.activity = data.jobRiskAssessmentHasRisks[i].risk.activity
        obj.riskDetails =   data.jobRiskAssessmentHasRisks[i].risk.riskDetails
        obj.initialRisk = data.jobRiskAssessmentHasRisks[i].risk.initialRisk
        obj.initialRiskSeverity =   data.jobRiskAssessmentHasRisks[i].risk.initialRiskSeverity
        obj.initialRiskProbability = data.jobRiskAssessmentHasRisks[i].risk.initialRiskProbability
        obj.comapasatoryMeasures = data.jobRiskAssessmentHasRisks[i].comapasatoryMeasures
        obj.residualRiskProbability = data.jobRiskAssessmentHasRisks[i].residualRiskProbability
        obj.residualRisk = data.jobRiskAssessmentHasRisks[i].residualRisk
        obj.residualRiskSeverity = data.jobRiskAssessmentHasRisks[i].residualRiskSeverity
        obj.nokiaComment = data.jobRiskAssessmentHasRisks[i].nokiaComment
        obj.nokiaValidation = data.jobRiskAssessmentHasRisks[i].nokiaValidation 
        if (data.jobRiskAssessmentHasRisks[i].jobRiskAssessmentsFiles.length) {
          obj.files = data.jobRiskAssessmentHasRisks[i].jobRiskAssessmentsFiles[0].url
          obj.files_id = data.jobRiskAssessmentHasRisks[i].jobRiskAssessmentsFiles[0].id
        }
       
        this.repProduit.push(obj)
       
      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        this.ADD_ITEM_PRODUIT(this.produits_computed_show, this.repProduit[j])
      }
      //this.documents = ''
      /*  if (data.jobRiskAssessmentFiles.length) {
        for (let i = 0; i < data.jobRiskAssessmentFiles.length; i++) {
          this.bene_object = 
            {
              'id': data.jobRiskAssessmentFiles[i].id,
              'url':(data.jobRiskAssessmentFiles[i].url).substring(8)
            }
          
          this.produits_computed.push(this.bene_object)
        }
      } */
      this.activePrompt3 = true
    },
    validateData (data) {
      this.intialise()
      this.produits_computed_show = []
      this.repProduit = []
      this.id = data.id
      const z = data.jobRiskAssessmentHasRisks.length
      for (let i = 0; i < z; i++) {
        const obj = {
          id: '',
          id_job:'',
          activity:'',
          initialRisk: '',
          initialRiskSeverity: '',
          initialRiskProbability: '',
          comapasatoryMeasures: '',
          residualRisk: '',
          residualRiskSeverity: '',
          residualRiskProbability: '',
          riskDetails: '',
          nokiaComment:'',
          nokiaValidation:'',
          files:'',
          files_id:''
        }

        obj.id = data.jobRiskAssessmentHasRisks[i].risk.id
        obj.id_job = data.jobRiskAssessmentHasRisks[i].id
        obj.activity = data.jobRiskAssessmentHasRisks[i].risk.activity
        obj.riskDetails =   data.jobRiskAssessmentHasRisks[i].risk.riskDetails
        obj.initialRisk = data.jobRiskAssessmentHasRisks[i].risk.initialRisk
        obj.initialRiskSeverity =   data.jobRiskAssessmentHasRisks[i].risk.initialRiskSeverity
        obj.initialRiskProbability = data.jobRiskAssessmentHasRisks[i].risk.initialRiskProbability
        obj.comapasatoryMeasures = data.jobRiskAssessmentHasRisks[i].comapasatoryMeasures
        obj.residualRiskProbability = data.jobRiskAssessmentHasRisks[i].residualRiskProbability
        obj.residualRisk = data.jobRiskAssessmentHasRisks[i].residualRisk
        obj.residualRiskSeverity = data.jobRiskAssessmentHasRisks[i].residualRiskSeverity
        obj.nokiaComment = data.jobRiskAssessmentHasRisks[i].nokiaComment
        obj.nokiaValidation = data.jobRiskAssessmentHasRisks[i].nokiaValidation 
        if (data.jobRiskAssessmentHasRisks[i].jobRiskAssessmentsFiles.length) {
          obj.files = data.jobRiskAssessmentHasRisks[i].jobRiskAssessmentsFiles[i].url
          obj.files_id = data.jobRiskAssessmentHasRisks[i].jobRiskAssessmentsFiles[i].id
        }

        this.repProduit.push(obj)
       
      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        this.ADD_ITEM_PRODUIT(this.produits_computed_show, this.repProduit[j])
      }
      //this.documents = ''
      /*  if (data.jobRiskAssessmentFiles.length) {
        for (let i = 0; i < data.jobRiskAssessmentFiles.length; i++) {
          this.bene_object = 
            {
              'id': data.jobRiskAssessmentFiles[i].id,
              'url':(data.jobRiskAssessmentFiles[i].url).substring(8)
            }
          
          this.produits_computed.push(this.bene_object)
        }
      } */
      this.activePrompt5 = true
    },
    editData (data) {
      this.intialise()
      this.produits_computed_show = []
      this.repProduit = []
      this.id = data.id
      const z = data.jobRiskAssessmentHasRisks.length
      for (let i = 0; i < z; i++) {
        const obj = {
          id: '',
          activity:'',
          initialRisk: '',
          initialRiskSeverity: '',
          initialRiskProbability: '',
          comapasatoryMeasures: '',
          residualRisk: '',
          residualRiskSeverity: '',
          residualRiskProbability: '',
          riskDetails: '',
          nokiaComment:'',
          nokiaValidation:'',
          files:'',
          files_id:''
        }

        obj.id = data.jobRiskAssessmentHasRisks[i].risk.id
        obj.activity = data.jobRiskAssessmentHasRisks[i].risk.activity
        obj.riskDetails =   data.jobRiskAssessmentHasRisks[i].risk.riskDetails
        obj.initialRisk = data.jobRiskAssessmentHasRisks[i].risk.initialRisk
        obj.initialRiskSeverity =   data.jobRiskAssessmentHasRisks[i].risk.initialRiskSeverity
        obj.initialRiskProbability = data.jobRiskAssessmentHasRisks[i].risk.initialRiskProbability
        obj.comapasatoryMeasures = data.jobRiskAssessmentHasRisks[i].comapasatoryMeasures
        obj.residualRiskProbability = data.jobRiskAssessmentHasRisks[i].residualRiskProbability
        obj.residualRisk = data.jobRiskAssessmentHasRisks[i].residualRisk
        obj.residualRiskSeverity = data.jobRiskAssessmentHasRisks[i].residualRiskSeverity
        obj.nokiaComment = data.jobRiskAssessmentHasRisks[i].nokiaComment
        obj.nokiaValidation = data.jobRiskAssessmentHasRisks[i].nokiaValidation 
        if (data.jobRiskAssessmentHasRisks[i].jobRiskAssessmentsFiles.length) {
          obj.files = data.jobRiskAssessmentHasRisks[i].jobRiskAssessmentsFiles[i].url
          obj.files_id = data.jobRiskAssessmentHasRisks[i].jobRiskAssessmentsFiles[i].id
        }
        this.repProduit.push(obj)
       
      }
      for (let j = 0; j <  this.repProduit.length; j++) {
        this.ADD_ITEM_PRODUIT(this.produits_computed_show, this.repProduit[j])
      }
      //this.documents = ''
      /*  if (data.jobRiskAssessmentFiles.length) {
        for (let i = 0; i < data.jobRiskAssessmentFiles.length; i++) {
          this.bene_object = 
            {
              'id': data.jobRiskAssessmentFiles[i].id,
              'url':(data.jobRiskAssessmentFiles[i].url).substring(8)
            }
          
          this.produits_computed.push(this.bene_object)
        }
      } */
      this.Check_update = true
      this.activePrompt2 = true
    },
    quit () {
      this.activePrompt3 = false
      this.$validator.reset()
      this.intialise()
      if (this.action_button === 1) {
        this.missionStep()
      } else if (this.action_button === 2) {
        this.validationStep()
      }
    },
    intialise () {
      this.jobrisk = {}
      this.jobRiskAssessment_object = []
      this.produits_computed_show = []
      this.risks_activity = []
      this.risks = []
      this.id = ''
      this.comapasatoryMeasures = []
      this.residualRisks = []
      this.residualRiskSeverities  = []
      this.residualRiskProbabilities = []
      this.Check_update = false
      this.activePrompt1 = false
      this.activePrompt2 = false

    },
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`jobRiskAssessments/${id}/`)
            .then(response => {
              this.getAlljobRiskAssessments()
              window.getPrendTaCom.success(this.$t('echec_risque_emploie'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('echec_risque_emploie') })
            })
        }
      })
  
    },
    submitData () {
      if (this.mission_id) {
        this.produits_store = []
        this.$vs.loading()
        if (this.Check_update === false) {
          this.produits_store = this.risks_activity
        } else if (this.Check_update === true) {
          this.produits_store = this.produits_computed_show
        }
        const formdata = new FormData()
        for (let i = 0; i < this.produits_store.length; i++) {
          const obj = {
            comapasatoryMeasures: '',
            risk: '',
            residualRisk: '',
            residualRiskSeverity: '',
            residualRiskProbabily: ''
          }
          obj.comapasatoryMeasures = this.produits_store[i].comapasatoryMeasures
          obj.risk = this.produits_store[i].id
          obj.residualRisk = parseInt(this.produits_store[i].residualRisk)
          obj.residualRiskSeverity = parseInt(this.produits_store[i].residualRiskSeverity)
          obj.residualRiskProbabily = parseInt(this.produits_store[i].residualRiskProbability)
          this.jobRiskAssessment_object.push(obj)
        }
        this.jobRiskAssessment_object = JSON.stringify(this.jobRiskAssessment_object)
        formdata.append('jobRiskAssessment_object', this.jobRiskAssessment_object)
        formdata.append('mission', this.mission_id)

        if (this.dataImg1_4.length) {
          for (let i = 0; i < this.dataImg1_4.length; i++) {
            formdata.append('files', this.dataImg1_4[i][0], this.dataImg1_4[i][0].name)
          }
        }

        let url = 'jobRiskAssessments/'
        let methods = 'post'
        const message = {
          error: this.$t('save_error'),
          success: this.$t('risque_emploie_save')
        }
        if (this.id) {
          url += `${this.id}/`
          methods = 'put'
          message.success = this.$t('risque_emploie_update')
  
        }
        //this.$http[methods](url, this.jobrisk)

        this.$http[methods](url, formdata)
          .then((response) => {
            window.getPrendTaCom.success(message.success, response)
            this.intialise()
            this.missionStep()

          })
          .catch((error) => {
            const clefs = Object.keys(error.response.data)
            for (let i = 0; i < clefs.length; i++) {
              const item = clefs[i]
              if (item === 'name') {
                this.$vs.notify({
                  time: 4000,
                  title: 'ATTENTION',
                  text: this.$t('name'),
                  color: 'warning',
                  position: 'top-center'
                })
              } else if (item === 'tasks') {
                this.$vs.notify({
                  time: 4000,
                  title: 'ATTENTION',
                  text: this.$t('tasks'),
                  color: 'warning',
                  position: 'top-center'
                })
              } else if (item === 'staffs') {
                this.$vs.notify({
                  time: 4000,
                  title: 'ATTENTION',
                  text: this.$t('staffs'),
                  color: 'warning',
                  position: 'top-center'
                })
              } else if (item === 'vehicles') {
                this.$vs.notify({
                  time: 4000,
                  title: 'ATTENTION',
                  text: this.$t('vehicles'),
                  color: 'warning',
                  position: 'top-center'
                })
              } else if (item === 'liftingEquipments') {
                this.$vs.notify({
                  time: 4000,
                  title: 'ATTENTION',
                  text: this.$t('liftingEquipments'),
                  color: 'warning',
                  position: 'top-center'
                })
              }
            }
            this.$vs.notify({
              time: 8000,
              title: this.$t('save_error_team'),
              text: error.response.data.error,
              color: 'danger',
              position: 'top-right'
            })
            this.$vs.loading.close()
          })
      } 
        
    },
    updateCurrImg1_3 (input) {
      if (input.target.files && input.target.files.length >0) {
        //for (let i = 0; i < input.target.files.length; i++) {
        this.dataImg1_4.push(input.target.files)
        this.name_file1_4.push(input.target.files.name)
        //}
      }
    },

  
    UPDATE_ITEM_PRODUIT (produits, produit) {
      const itemIndex = produits.findIndex((p) => p.id === produit.id)
      Object.assign(produits[itemIndex], produit)
    },
    UPDATE_ITEM_PRODUIT_2 (produits, produit) {
      const itemIndex = produits.findIndex((p) => p.id_obj === produit.id_obj)
      Object.assign(produits[itemIndex], produit)
    },
    ADD_ITEM_PRODUIT (produits, produit) {
      produits.push(produit)
    },
    getAlllibraries () {
      this.$vs.loading()
      this.$http.get('risk-library/')
        .then((response) => {
          const results = response.data
          results.forEach(event => {
            this.libraries.push({
              id: event.id,
              name:(event.file_path).substring(8),
              file_path:`https://dev.jra.mitigarisk.com/digiraDev/${event.file_path}`
            })
              
          })
          this.$vs.loading.close()    
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllrisks () {
      this.$vs.loading()
      this.$http.get('risks/')
        .then((response) => {
          this.risks_activity = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {
    window.jobRiskAssessments = this
    this.getAllrisks()
    this.getAlllibraries()


  },
  mounted () {
    this.isMounted = true
    this.user_role = JSON.parse(localStorage.getItem('userInfo')).role
    this.missionControle_data = window.Planifications.missionControle
    this.mission_data = window.Planifications.activePrompt1_journey
    this.mission_data_up = window.Planifications.planification_mission_data
    this.mission_id = this.mission_data_up.id
    this.mission_name = this.mission_data_up.poNumber
    if (this.mission_data === 1) {
      this.addNewData()
    } 
    if (this.mission_data === 2) {
      this.ShowData(this.mission_data_up.missionJobRiskAssessment[0])
    } 
    if (this.mission_data === 3) {
      this.editData(this.mission_data_up.missionJobRiskAssessment[0])
    } 
    if (this.mission_data === 4) {
      this.validateData(this.mission_data_up.missionJobRiskAssessment[0])
    } 
    if (this.missionControle_data === 1) {
      this.action_button = 1
    } else if (this.missionControle_data === 2) {
      this.action_button = 2

    }
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
  
      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  .matrix {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  //grid-gap: 5px;
}

.matrix div {
  padding: 5px;
  //border: 1px solid #ddd;
  text-align: center;
  cursor: pointer;
}

.matrix div.selected {
  background-color: #ccc;
}

.matrix div.level-negligible {
  background-color: #00ff00;
}

.matrix div.level-moderate {
  background-color: #ffff00;
}

.matrix div.level-high {
  background-color: #ff0000;
}

.legende {
  margin-top: 5px;
}

.legende ul {
  list-style: none;
  padding: 0;
}

.legende li {
  margin-bottom: 5px;
}
  </style>
  <style scoped>
  
  </style>
  